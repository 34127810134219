import React from "react";
import {
    Button, ButtonGroup, Container, ButtonToolbar, Jumbotron, Card, Form, Row, Col,
    Dropdown, DropdownButton, Modal
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import {
    Rootcontainer,
    SearchDiv,
    MainContainer,
    RootDiv,
    RootTitle,
    RootBal,
    RootRight,
    Amt,
    MoreDetails,
    RootLeft,
    RootValue,
    CustomerStatus,
    BtnGroupRight,
    STBGroup,
    STBDetails,
    CustomerStatusDiv,
    ActivPackage,
    ChannelBox,
    ChannelName,
    ChannelPrice,
    AddPackageHeading
} from "./styled";
import "./index.css";
import { Redirect } from "react-router-dom";
import { Calendar } from 'react-calendar';
import GeneralValues from '../../GobelElements/Values';

import {
    MdPermIdentity,
    MdHome,
    MdFiberPin,
    MdCreditCard,
    MdRefresh,
    MdAddCircle,
    MdRemoveCircle,
    MdPowerSettingsNew,
    MdArrowBack
} from "react-icons/md";
import loadinggif from "../../assets/loding-gif.gif";
import API from "../../Api";
import logo from "../../assets/logo-3.png";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import Drawer from "react-drag-drawer";
import Undraw from "react-undraw";
import axios from 'axios';
import Date1 from './Date';
import Details from '../../GobelElements/Values';
import { FaPlus, FaCircle } from "react-icons/fa";
import Loader from 'react-loader-spinner';

import "react-datepicker/dist/react-datepicker.css";
var d = new Date("YYYY-MM");
export default class Report extends React.Component {
    constructor(props) {
        super(props);


        this.state = {


            lcoID: localStorage.getItem("userID"),
            lcoPassword: localStorage.getItem("userPassword"),
            domainName: localStorage.getItem("domain"),
            isloggedOut: false,
            isShowLogOutDialog: false,
            lcoBalance: "",
            lcoName: "",
            lcocode: "",
            fromYear: 0,
            fromMon: 0,
            fromDay: 0,
            toYear: 0,
            toMon: 0,
            toDay: 0,
            whichDocument: "tinvoicelco",
            isSpinnerVisible: false,
            isEmptyError: "",
            DocumentType: ["tinvoicelco", "dumpdebitlog", "disconnected", "expiry", "monthlybill"],
            startDate: "",
            endDate: "",
            selectedMonth: parseInt(new Date().getMonth()) + 1,
            selectedYear: parseInt(new Date().getFullYear()),
            ledgerData: [],
            isshowLedgerData: false,
            ledger: {},
            inStockListSearchText: "",
            inStockDataList: [],
            isShowPackageDialog: false,
            selectedPackageData: "",
            selectedAddress: "",
            isShowAddressDialog: false,
            errorMessage:""

        };

        this.getLCO_Details();
        //this.getdata1()

        console.log("try", this.props.tableData);

    }
    // componentDidMount() {
    //     this.selectedMonth= new Date().getMonth()+1;
    // }
    // componentWillMount() {
    //    this.selectedMonth= new Date().getMonth()+1;
    // }
    calStartDate(value) {
        console.log(value)
        // var d=new Date(value);
        console.log(value.getDate(), value.getMonth(), value.getFullYear())
        debugger;
        this.setState({ fromDay: value.getDate(), fromMon: value.getMonth() + 1, fromYear: value.getFullYear(), startDate: value })
        //console.log(d.getDate(),d.getMonth(),d.getFullYear())
        debugger;

    }
    calEndDate(value) {
        console.log(value)
        // var d=new Date(value);
        console.log(value.getDate(), value.getMonth() + 1);

        this.setState({ toDay: value.getDate(), toMon: value.getMonth() + 1, toYear: value.getFullYear(), endDate: value })
        //console.log(d.getDate(),d.getMonth(),d.getFullYear())
        debugger;
    }

    getLCO_Details() {
        debugger;
        this.setState({ isSpinnerVisible: true });
        const { lcoID, lcoPassword, domainName } = this.state;
        API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
            .then(response => response.json())
            .then(responsejson => {
                this.setState({
                    lcoName: responsejson.lconame,
                    lcoBalance: responsejson.balance,
                    lcocode: responsejson.lcocode
                });

            });
    }

    setCasType(e) {
        console.log("selected value", e.target.value);
        this.setState({ whichDocument: e.target.value });
        if (e.target.value == "monthlybill") {
            this.setState({ isShowMonthlyBill: true });
        } else {
            this.setState({ isShowMonthlyBill: false });
        }
    }
    getCSVFile() {
        const { fromDay, fromMon, fromYear, toDay, toMon, toYear, whichDocument, lcocode, lcoID, lcoPassword, domainName, selectedYear, selectedMonth } = this.state;
        console.log(fromDay, fromMon, fromYear, toDay, toMon, toYear, whichDocument)

        if (selectedYear && selectedMonth) {
            this.setState({ isEmptyError: "", isSpinnerVisible: true });
            console.log("data in file", fromDay, fromMon, fromYear, toDay, toMon, toYear, whichDocument, lcocode, lcoID, lcoPassword);
            console.log("url", `${Details.url}/api/reportsdownload/?command=monthlybill&username=${lcoID}&password=${lcoPassword}&asondate=${this.state.selectedYear}-${this.state.selectedMonth}&lcocode=${lcocode}&ssr=yes&csvdownload=yes`);
            fetch(`${Details.url}/api/reportsdownload/?command=monthlybill&username=${lcoID}&password=${lcoPassword}&asondate=${this.state.selectedYear}-${this.state.selectedMonth}&lcocode=${lcocode}&ssr=yes&csvdownload=yes`)
                .then(res => res.json())
                .then(resJson => {
                    console.log("resjson", resJson);
                    debugger;
                    this.setState({ isSpinnerVisible: false, isEmptyError: "", ledgerData: resJson.data, isshowLedgerData: true, ledger: resJson });

                }).catch(e => {
                    console.log("error", e);
                    this.setState({ isSpinnerVisible: false, isEmptyError: "Error" });
                })
            this.setState({ isSpinnerVisible: true });
            // axios({

            //     url: `${Details.url}/api/reportsdownload/?command=${whichDocument}&username=${lcoID}&password=${lcoPassword}&asondate=${this.state.selectedYear}-${this.state.selectedMonth}&lcocode=${lcocode}&ssr=yes&csvdownload=yes`,
            //     method: 'GET',
            //     responseType: 'blob', // important
            // }).then((response) => {
            //     console.log("data", response);
            //     debugger;
            //     const url = window.URL.createObjectURL(new Blob([response.data]));
            //     const link = document.createElement('a');
            //     link.href = url;
            //     link.setAttribute('download', `${whichDocument}.csv`);
            //     link.click();
            //     window.URL.revokeObjectURL(url);
            //     this.setState({ isSpinnerVisible: false,selectedMonth:1,selectedYear:2020 });
            // }).catch((error) => {
            //     console.log("error", error);
            //     this.setState({ isSpinnerVisible: false, isEmptyError: "Unable to process !" });

            // });
        } else {
            this.setState({ isEmptyError: "Please fill all field !" })
        }

    }
    stockListSearch(text) {

        var { lcoID, lcoPassword, domainName, inStockListSearchText } = this.state;
        if (text.length > 2) {
            this.setState({ inStockDataList: [], isShowInStockList: true, isShowStockLoader: true, noData: "" });

            API.stockListSearch(lcoID, lcoPassword, "liststock", text, 1, domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    if (responseJson.count) {


                        this.setState({ inStockDataList: responseJson.content, isShowInStockList: true, inStockCurrentPage: 1, totalCountInStock: responseJson.count, isShowStockLoader: false });
                        console.log("In stock list", this.state.inStockDataList);
                    } else {
                        this.setState({ inStockDataList: [], isShowStockLoader: false, noData: "No data found!" });
                    }
                });
        } else {
            this.setState({ isShowInStockList: false, isShowInStockList: false, isShowStockLoader: false, noData: "" });
        }
    }
    getSTBDetails(item) {
        console.log("item", item);
        if (item.Customer) {
            this.setState({ spinnerVisible: true });
            const { lcoID, lcoPassword, domainName, searchText } = this.state;
            API.STBSearch(lcoID, lcoPassword, "stbsearch", item.SNo, domainName)
                .then((response) => response.json())
                .then((responsejson) => {
                    console.log("response", responsejson);
                    if (responsejson.message == "no data") {
                        this.setState({ spinnerVisible: false });
                    } else {
                        localStorage.setItem("currentlco", item.Lcocode);
                        this.setState({ spinnerVisible: false });
                        this.props.moveToCustomerDetails("customerdetails", responsejson, this.state.searchText);
                    }
                });
        }
        else {
            this.setState({ isShowNodataDialog: true })
        }

    }


    logOutFunc() {
        if (this.state.isloggedOut) {
            return <Redirect to="/login" />;
        } else {
            return null;
        }
    }
    logOut() {
        localStorage.removeItem("userID");
        const username = localStorage.getItem("userID");
        if (!username) {
            this.setState({ isloggedOut: true });
        }
    }
    backTOTrandactions() {
        this.props.onMoveToTransactions();
    }
    dowloadCSvm(data) {
        console.log("data", data);
        let csvRow = [];
        let A = [['STB',  'Customer', 'Phone number', 'CustomerAddress', "PaidCGST", 'PaidSGST', 'PaidAmount',  'UnpaidAmount',"Products"]];
        for (var m of data) {
            A.push([`'${m.STB}`,  m.Customer, `'${m.CustomerMobile}`, `'${m.CustomerAddress}`.replaceAll(/,/g, "."), m.PaidCGST, m.PaidSGST, `'${m.PaidAmount}`, `'${m.UnpaidAmount}`,`${m.Product.join('; ')}`]);
        }
        console.log("A", A);
        for (var i = 0; i < A.length; i++) {
            csvRow.push(A[i].join(","))
        }
        console.log("csvRow", csvRow);
        var csvString = csvRow.join(",\n");
        console.log("csvString", csvString);
        var a = document.createElement('a');
        a.href = 'data:attachment/csv,' + csvString;
        a.target = "_Blank"
        a.download = `Monthly_Leger_${this.state.Lcocode}.csv`;
        document.body.appendChild(a);
        a.click();
    }

    render() {
        return (
            <Rootcontainer >
                <LoadingOverlay
                    className={this.state.isSpinnerVisible ? "displaySpinner" : ""}
                    overlay={true}
                    active={this.state.isSpinnerVisible}
                    spinner={
                        <img
                            src={loadinggif}
                            className="spinnerContent"
                            color="green"
                            style={{ marginLeft: 100 }}
                            alt="logo"
                        />
                    }
                ></LoadingOverlay>
                <Drawer
                    open={this.state.isShowLogOutDialog}
                    onRequestClose={this.toggle}
                    direction="left"
                >
                    <Card>
                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 15, fontWeight: "bold" }}>
                                Do you want{" "}
                                <span style={{ fontSize: "1.5em", color: "red" }}>Log out</span>{" "}
                                ?
                            </div>
                            <Undraw className="undrawimg" name="monitor" />
                            <Button
                                style={{ marginLeft: 20 }}
                                className="success"
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.setState({ isShowLogOutDialog: false });
                                }}
                            >
                                Cancel
                            </Button>

                            <Button
                                style={{ backgroundColor: "red" }}
                                className="danger"
                                className="mt-3 pull-right"
                                onClick={() => {
                                    // this.props.hindNodataDialog();
                                    this.logOut();
                                }}
                            >
                                OK
                            </Button>
                        </Card.Body>
                    </Card>
                </Drawer>

                <div className="header-div">
                    <MdArrowBack
                        onClick={() => this.backTOTrandactions()}
                        className="back-button"
                    />
                    <h3 style={{ marginLeft: "90px", textTransform: "uppercase", marginRight: "-50px" }}>Monthly Ledger</h3>
                    <img
                        onClick={() => { this.props.moveToDashBoard() }}
                        src={GeneralValues.circlelogo}
                        style={{ float: "left", marginLeft: " -168px" }}
                        className="logo-img"
                        alt="logo"
                    />

                    <MdPowerSettingsNew
                        onClick={() => {
                            this.setState({ isShowLogOutDialog: true });
                        }}
                        value="Logout"
                        className="fontSize login"
                    />
                    <input type="text" text="Search" placeholder="Global Search..."
                        onChange={(e) => {
                            this.setState({ inStockListSearchText: e.target.value })
                            this.stockListSearch(e.target.value);
                        }}

                        value={this.state.inStockListSearchText}
                        className="input-STB"
                        style={{ backgroundColor: "transparent", borderColor: "transparent", borderBottom: "2px solid white", color: "white", textAlign: "center", fontSize: 15, borderRight: "2px solid white", fontWeight: "bold", alignSelf: "end", marginLeft: "50%" }} />
                    <div style={{
                        position: "absolute",
                        display: this.state.isShowInStockList ? "block" : "none",
                        listStyle: "none",
                        backgroundColor: "white",
                        padding: 0,
                        boxShadow: "0px 1px 3px #e9b42a",
                        zIndex: 9,
                        width: "22vw",
                        top: 60,
                        overflow: "auto",
                        maxHeight: "30vh",
                        marginLeft: "65%"

                    }}
                        onScroll={(e) => this.handleScroll(e)}>
                        <label style={{ fontSize: 15, fontWeight: "bold", color: "rgb(26, 172, 195)", marginLeft: 10 }}>List:</label>
                        <div style={{ textAlign: "center", color: "gray" }}>{this.state.noData}</div>
                        <ul style={{ listStyle: "none", padding: 10 }}>

                            {this.state.inStockDataList.map((item, i) => {

                                return (


                                    <li className="search-list"
                                        onClick={() => { this.getSTBDetails(item) }}
                                        style={{ fontSize: 15, color: "black", lineHeight: 2, borderBottom: "1px solid rgba(26, 172, 195,0.5)", cursor: "pointer" }}>
                                        <div>
                                            <lable style={{ display: "block" }}>
                                                Name:
                                                {item.Customer ? item.Customer : <FaPlus style={{
                                                    background: " #0a3f65",
                                                    color: "white", width: "20px", height: "20px", padding: "2px", borderRadius: "10px", marginLeft: "8px"
                                                }} />}

                                                <FaCircle style={{
                                                    display: item.status ? "inline-block" : "none",
                                                    height: "12px", float: "right", marginTop: "8px",
                                                    color: item.status == "Live" ? "green" : "red",
                                                }} />


                                            </lable>

                                            <lable style={{ display: "block" }}>STB: {item.SNo}

                                                <div style={{
                                                    fontSize: "12px", display: item.paid ? "inline-block" : "none", float: "right", color: "white",
                                                    padding: "0px 7px", height: "25px",
                                                    backgroundColor: item.paid === "paid" ? "green" : "red",
                                                }}>{item.paid}  </div>
                                            </lable>

                                            <lable style={{ display: "block", fontSize: "12px" }}>Lco: {item.Lconame}

                                                <div style={{ fontSize: "12px", display: "inline-block", float: "right", color: "red" }}>({item.Lcocode})
                                                </div>
                                            </lable>
                                        </div>
                                    </li>

                                );
                            })}
                        </ul>

                        <Loader
                            visible={this.state.isShowStockLoader}
                            style={{ textAlign: "center", marginBottom: 10, }}
                            type="ThreeDots"
                            color="#00BFFF"
                            height="10"
                            width="20"
                        />
                    </div>
                </div>

                <RootDiv>
                    <RootLeft>
                        <RootTitle>Name :</RootTitle>

                        <RootValue
                            style={{ cursor: "pointer" }}
                        //   onClick={() => {
                        //     this.props.onClickProfile();
                        //   }}
                        >
                            {this.state.lcoName}
                        </RootValue>
                        <div className="lcocode">
                            (<span style={{ color: "black" }}>Lco code:</span>{" "}
                            {this.state.lcocode})
                        </div>
                    </RootLeft>

                    <RootRight>
                        <RootTitle>Balance :</RootTitle>

                        <RootValue>
                            {"\u20B9"} {this.state.lcoBalance}
                        </RootValue>
                    </RootRight>
                </RootDiv>
                <MainContainer>
                <Form.Label><span>{this.state.errorMessage}</span></Form.Label>
                    <Form className="form-div" style={{ marginTop: 20 }}>
                        <h4 style={{ fontSize: 15, fontWeight: "bold", alignSelf: "center", color: "red" }}>{this.state.isEmptyError}</h4>
                        <h3> Monthly Ledger form</h3>

                        <Row>
                            <Col xs={12} md={4} lg={3} sm={6} >
                                <Form.Group>
                                    <Form.Label>Type <span>*</span></Form.Label>

                                    <label style={{ width: "80%", display: "block" }}>Monthly ledger</label>
                                    {/* <select title="DRM List" style={{ width: "80%", display: "block" }}
                                        onChange={(e) => { this.setCasType(e) }}

                                    >
                                        {this.state.DocumentType.map((item, i) => {
                                            return (
                                                <option value={item}>{item}</option>
                                            );
                                        })}
                                    </select> */}
                                </Form.Group>
                            </Col>




                            <Col xs={12} md={4} lg={3} sm={6} >
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Month/Year</Form.Label>
                                    <div style={{ display: "flex", justifyContent: "space-around" }}>

                                        <div style={{ float: "left" }}>
                                            <h4 style={{ fontSize: 12 }}>Month</h4>
                                            <select title="DRM List" style={{ width: "100%" }}
                                                value={this.state.selectedMonth}

                                                onChange={(e) => { this.setState({ selectedMonth: e.target.value }) }}

                                            >
                                                {Date1.month.map((item, i) => {
                                                    return (
                                                        <option value={item}>{item}</option>
                                                    );
                                                })}


                                            </select>
                                        </div>
                                        <div style={{ float: "left" }}>
                                            <h4 style={{ fontSize: 12, marginLeft: 5 }}>year</h4>
                                            <select title="DRM List" style={{ width: "100%" }}
                                                value={this.state.selectedYear}
                                                onChange={(e) => { this.setState({ selectedYear: e.target.value }) }}

                                            >
                                                {Date1.year.map((item, i) => {
                                                    return (
                                                        <option value={item}>{item}</option>
                                                    );
                                                })}


                                            </select>
                                        </div>
                                    </div>

                                </Form.Group>

                            </Col>



                        </Row>
                        <div style={{ textAlign: "end", marginRight: 20 }}>
                            <Button
                                onClick={() => { this.getCSVFile() }}
                                // style={{ textAlign: "flex-end" }}
                                style={{ backgroundColor: GeneralValues.mustard, borderWidth: 0, textAlign: "flex-end" }}
                            >
                                Show
                            </Button>
                            
                        </div>


                    </Form>
                    {this.state.isshowLedgerData ?
                        <div style={{ marginTop: 10, marginBottom: 10 }}>

                            <label style={{ marginLeft: 15 }}>Opening Balance : <span style={{ color: "green", fontSize: 17 }}>{this.state.ledger.openbalance}</span></label>
                            <label style={{ marginLeft: 15 }}>Closing Balance : <span style={{ color: "green", fontSize: 17 }}>{this.state.ledger.closingbalance}</span></label>
                            <label style={{ marginLeft: 15 }}>Total paid : <span style={{ color: "green", fontSize: 17 }}>{this.state.ledger.totalpaid ? this.state.ledger.totalpaid : 0}</span></label>
                            <label style={{ marginLeft: 15 }}>Paid count : <span style={{ color: "green", fontSize: 17 }}>{this.state.ledger.totalpaidcount ? this.state.ledger.totalpaidcount : 0}</span></label>
                            <label style={{ marginLeft: 15 }}>Unpaid : <span style={{ color: "green", fontSize: 17 }}>{this.state.ledger.totalunpaid}</span></label>
                            <label style={{ marginLeft: 15 }}>month Credits : <span style={{ color: "green", fontSize: 17 }}>{this.state.ledger.monthcredits}</span></label>
                            <Button
                                onClick={() => { 
                                    if(this.state.ledgerData.length > 0){
                                        this.setState({errorMessage:""})
                                        this.dowloadCSvm(this.state.ledgerData)
                                    }else{
                                        this.setState({errorMessage:"Data not found"})
                                    }
                                   
                                 }}
                                // style={{ textAlign: "flex-end" }}
                                style={{ backgroundColor: GeneralValues.mustard, borderWidth: 0, textAlign: "flex-end",marginLeft:15 }}
                            >
                                Download List
                            </Button>

                        </div>
                        : null
                    }

                    {this.state.isshowLedgerData
                        ?
                        <table className="table table-striped">
                            <thead>
                                <th>Sno</th>
                                <th>customer name</th>
                                <th>customer address</th>
                                <th>mobile no</th>
                                <th>stb id</th>
                                <th>vc no</th>
                                <th>base amount</th>
                                <th>cgst 9%</th>
                                <th>sgst 9%</th>
                                <th>total amount</th>
                                <th>packages</th>
                            </thead>
                            <tbody className="total-records">
                                {this.state.ledgerData.map((data, i) => {
                                    return (
                                        <tr>
                                            <td >{i + 1}</td>
                                            <td>{data.Customer}</td>
                                            <td style={{ fontSize: 12, maxLines: 2, maxheight: 40, overflow: "hidden" }}>
                                                <div style={{ height: 40, overflow: "hidden" }}>  {data.CustomerAddress}</div>

                                                {data.CustomerAddress.length > 20 ?
                                                    <label onClick={() => {
                                                        this.setState({ selectedAddress: data.CustomerAddress, isShowAddressDialog: true })
                                                    }} style={{ color: GeneralValues.mustard, textDecoration: "underline" }}>More</label>
                                                    : null}
                                            </td>
                                            <td>{data.CustomerMobile}</td>
                                            <td>{data.STB}</td>
                                            <td>{data.VC}</td>
                                            <td>{data.Base}</td>
                                            <td>{data.PaidCGST}</td>
                                            <td>{data.PaidSGST}</td>
                                            <td>{data.PaidAmount}</td>

                                            <td style={{ maxLines: 2, maxHeight: 40, overflow: "hidden", textOverflow: "ellipsis" }}>
                                                <div style={{ height: 40, overflow: "hidden" }}> {data.Product}</div>
                                                {data.Product ? <label onClick={() => {
                                                    this.setState({ selectedPackageData: data.Product, isShowPackageDialog: true })
                                                }} style={{ color: GeneralValues.mustard, textDecoration: "underline" }}>More</label> : null}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        : null

                    }

                    <Drawer

                        open={this.state.isShowPackageDialog}
                        onRequestClose={this.toggle}
                        direction='bottom'
                    >

                        <Card >


                            <Card.Body className="pl-3 pr-3">
                                <div style={{ fontSize: 18, fontWeight: "bold", color: "red" }}>Packages:</div>

                                <div style={{ marginLeft: 50, maxHeight: "30vh", marginRight: 30, color: GeneralValues.mustard, overflow: "auto", marginTop: 20 }}>
                                    {this.state.selectedPackageData ?
                                        this.state.selectedPackageData.map((item, i) => {
                                            return (
                                                <label style={{ fontSize: 13, marginRight: 10 }}>{`${item},`}</label>
                                            );
                                        }) : null
                                    }
                                </div>


                                <Button
                                    style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                    className="mt-3 pull-right"
                                    onClick={() => {

                                        this.setState({ isShowPackageDialog: false });

                                    }}
                                >OK</Button>



                            </Card.Body>
                        </Card>


                    </Drawer>

                    <Drawer

                        open={this.state.isShowAddressDialog}
                        onRequestClose={this.toggle}
                        direction='bottom'
                    >

                        <Card >


                            <Card.Body className="pl-3 pr-3">
                                <div style={{ fontSize: 18, fontWeight: "bold", color: "red" }}>Address:</div>

                                <div style={{ marginLeft: 50, maxHeight: "30vh", marginRight: 30, color: GeneralValues.mustard, overflow: "auto", marginTop: 20 }}>
                                    <label>{this.state.selectedAddress}</label>
                                </div>


                                <Button
                                    style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                    className="mt-3 pull-right"
                                    onClick={() => {

                                        this.setState({ isShowAddressDialog: false });

                                    }}
                                >OK</Button>



                            </Card.Body>
                        </Card>


                    </Drawer>


                </MainContainer>

                {this.logOutFunc()}
            </Rootcontainer>
        );
    }
}








