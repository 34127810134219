import React from "react";

import { Redirect } from "react-router-dom";
import loadinggif from "../../assets/loding-gif.gif";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Card,
  Modal
} from "react-bootstrap";

import "../UnmappedSTB/index.css";
import API from "../../Api";

import { MdPowerSettingsNew ,MdArrowBack} from "react-icons/md";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import {
  Rootcontainer,
  RootDiv,
  RootTitle,
  RootBal,
  RootRight,
  RootLeft,
  RootValue,
  FooterUl,
  FooterLi
} from "./styled";
import { MdAdd } from "react-icons/md";
import Drawer from "react-drag-drawer";
import Undraw from "react-undraw";
import GeneralValues from '../../GobelElements/Values';
import { FaPlus,FaCircle } from "react-icons/fa";
import Loader from 'react-loader-spinner';
export default class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloggedOut: false,
      lcoID: localStorage.getItem("userID"),
      lcoPassword: localStorage.getItem("userPassword"),
      domainName: localStorage.getItem("domain"),
      unmappedSTB: [],
      errorMessage: "",
      spinnerVisible: false,
      stbName: "",
      stbNumber: "",
      isShowLogOutDialog: false,
      inStockListSearchText: "",
           inStockDataList: []
    };
    console.log("username", localStorage.getItem("userID"));
    // this.getDashboardDetails();
    this.getLCO_Details();
  }
  getUnMappedSTB() {
    this.setState({ spinnerVisible: true });
    const { lcoID, lcoPassword, domainName } = this.state;
    API.getUnMappedSTB(lcoID, lcoPassword, "unmappedstb", domainName)
      .then(response => response.json())
      .then(responsejson => {
        this.setState({ unmappedSTB: responsejson, spinnerVisible: false });

        console.log("unmappedstb", this.state.unmappedSTB);
      });
  }
  logOutFunc() {
    if (this.state.isloggedOut) {
      return <Redirect to="/login" />;
    } else {
      return null;
    }
  }
  logOut() {
    localStorage.removeItem("userID");
    const username = localStorage.getItem("userID");
    if (!username) {
      this.setState({ isloggedOut: true });
    }
  }
  getLCO_Details() {
    this.setState({ isSpinnerVisible: true });
    const { lcoID, lcoPassword, domainName } = this.state;
    API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
      .then(response => response.json())
      .then(responsejson => {
        this.setState({
          lcoName: responsejson.lconame,
          lcoBalance: responsejson.balance,
          lcocode: responsejson.lcocode,
          isSpinnerVisible: false,
          isShowMantainanceDialog:responsejson.maint,
          maintText:responsejson.mainttext,
          maintIMG:responsejson.maintimage
        },()=>{
          this.getUnMappedSTB();
        });
      });
  }

  renderUnmappedSTB() {
    if (this.state.unmappedSTB) {
      return (
        <Row style={{ paddingLeft: 27, margin: "auto" }} className="mt-1">
          {this.state.unmappedSTB.map((data, i) => {
            return (
              <Col sm={6} lg={3} md={4} xs={12} className="mt-3 pl-0">
                <Card className="card-shadow">
                  <Card.Body class="pb-0 pl-3    pr-1 pt-2">
                    <Card.Title>{data.fields.SNo}</Card.Title>
                    <Card.Subtitle className="mb-1 mt-1 text-muted">
                      Model :{" "}
                      <label style={{ color: "red" }}>
                        {data.fields.Model}
                      </label>
                    </Card.Subtitle>
                    <i
                      className="stbicons"
                      onClick={() => this.AddCustomer(data.fields.SNo)}
                    >
                      <MdAdd />
                      Add
                    </i>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      );
    }
  }
  AddCustomer(item) {
    this.props.moveToCreateCustomerForm(item);
  }
  stockListSearch(text) {
 
    var { lcoID, lcoPassword, domainName, inStockListSearchText } = this.state;
    if (text.length > 2) {
        this.setState({ inStockDataList: [], isShowInStockList: true, isShowStockLoader: true, noData: "" });

        API.stockListSearch(lcoID, lcoPassword, "liststock", text, 1, domainName)
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson);
                if (responseJson.count) {


                    this.setState({ inStockDataList: responseJson.content, isShowInStockList: true, inStockCurrentPage: 1, totalCountInStock: responseJson.count, isShowStockLoader: false });
                    console.log("In stock list", this.state.inStockDataList);
                } else {
                    this.setState({ inStockDataList: [], isShowStockLoader: false, noData: "No data found!" });
                }
            });
    } else {
        this.setState({ isShowInStockList: false, isShowInStockList: false, isShowStockLoader: false, noData: "" });
    }
}
getSTBDetails(item) {
    console.log("item", item);
    if (item.Customer) {
      this.setState({ spinnerVisible: true });
      const { lcoID, lcoPassword, domainName, searchText } = this.state;
      API.STBSearch(lcoID, lcoPassword, "stbsearch", item.SNo, domainName)
        .then((response) => response.json())
        .then((responsejson) => {
          console.log("response", responsejson);
          if (responsejson.message == "no data") {
            this.setState({ spinnerVisible: false });
          } else {
            localStorage.setItem("currentlco", item.Lcocode);
            this.setState({ spinnerVisible: false });
            this.props.moveToCustomerDetails("customerdetails", responsejson, this.state.searchText);
          }
        });
    }
    else {
      this.setState({ isShowNodataDialog: true })
    }

  }


  render() {
    return (
      <Rootcontainer>
        <LoadingOverlay
          className={this.state.spinnerVisible ? "displaySpinner" : ""}
          overlay={true}
          active={this.state.spinnerVisible}
          spinner={
            <img
              src={loadinggif}
              className="spinnerContent"
              color="green"
              style={{ marginLeft: 100 }}
            />
          }
        ></LoadingOverlay>

        <Drawer
          open={this.state.isShowLogOutDialog}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 15, fontWeight: "bold" }}>
                Do you want{" "}
                <span style={{ fontSize: "1.5em", color: "red" }}>Log out</span>{" "}
                ?
              </div>
              <Undraw className="undrawimg" name="monitor" />
              <Button
                style={{ marginLeft: 20 }}
                className="success"
                className="mt-3 pull-right"
                onClick={() => {
                  this.setState({ isShowLogOutDialog: false });
                }}
              >
                Cancel
              </Button>

              <Button
                style={{ backgroundColor: "red" }}
                className="danger"
                className="mt-3 pull-right"
                onClick={() => {
                  // this.props.hindNodataDialog();
                  this.logOut();
                }}
              >
                OK
              </Button>
            </Card.Body>
          </Card>
        </Drawer>
        {/* <div className="header-div">
                    <h3>STB Mapping</h3>
                   
                    <MdPowerSettingsNew onClick={()=>{this.setState({isShowLogOutDialog:true})}} value="Logout" className="fontSize"/>
                </div> */}
        <div className="header-div">
        <MdArrowBack onClick={() => this.props.onMoveToTransactions()} className="back-button" style={{ marginRight: 15 }} />
          <h3 className="" style={{ marginLeft: "98px",marginRight:"-20px",textTransform:"uppercase" }}>
            STB Mapping
          </h3>
          <img
          onClick={()=>{this.props.moveToDashBoard()}}
            src={GeneralValues.circlelogo}
            style={{ float: "left", marginLeft: " -150px" }}
            className="logo-img"
            alt="logo"
          />

          <MdPowerSettingsNew
            onClick={() => {
              this.setState({ isShowLogOutDialog: true });
            }}
            value="Logout"
            className="fontSize login"
          />
          <input type="text" text="Search" placeholder="Global Search..."
                       onChange={(e) => {
                           this.setState({ inStockListSearchText: e.target.value })
                           this.stockListSearch(e.target.value);
                       }}
 
                       value={this.state.inStockListSearchText}
                       className="input-STB"
                       style={{ backgroundColor: "transparent", borderColor: "transparent", borderBottom: "2px solid white", color: "white", textAlign: "center", fontSize: 15, borderRight: "2px solid white", fontWeight: "bold",alignSelf:"end",marginLeft:"50%" }} />
                   <div style={{
                       position: "absolute",
                       display: this.state.isShowInStockList ? "block" : "none",
                       listStyle: "none",
                       backgroundColor: "white",
                       padding: 0,
                       boxShadow: "0px 1px 3px #e9b42a",
                       zIndex: 9,
                       width: "22vw",
                       top: 60,
                       overflow: "auto",
                       maxHeight: "30vh",
                       marginLeft:"65%"
 
                   }}
                       onScroll={(e) => this.handleScroll(e)}>
                       <label style={{ fontSize: 15, fontWeight: "bold", color: "rgb(26, 172, 195)", marginLeft: 10 }}>List:</label>
                       <div style={{ textAlign: "center", color: "gray" }}>{this.state.noData}</div>
                       <ul style={{ listStyle: "none", padding: 10 }}>
 
                           {this.state.inStockDataList.map((item, i) => {
 
                               return (
 
 
                                   <li className="search-list"
                                       onClick={() => { this.getSTBDetails(item) }}
                                       style={{ fontSize: 15, color: "black", lineHeight: 2, borderBottom: "1px solid rgba(26, 172, 195,0.5)", cursor: "pointer" }}>
                                       <div>
                                           <lable style={{ display: "block" }}>
                                               Name:
  {item.Customer ? item.Customer : <FaPlus style={{
                                                   background: " #0a3f65",
                                                   color: "white", width: "20px", height: "20px", padding: "2px", borderRadius: "10px", marginLeft: "8px"
                                               }} />}
 
                                               <FaCircle style={{
                                                   display: item.status ? "inline-block" : "none",
                                                   height: "12px", float: "right", marginTop: "8px",
                                                   color: item.status == "Live" ? "green" : "red",
                                               }} />
 
 
                                           </lable>
 
                                           <lable style={{ display: "block" }}>STB: {item.SNo}
 
                                               <div style={{
                                                   fontSize: "12px", display: item.paid ? "inline-block" : "none", float: "right", color: "white",
                                                   padding: "0px 7px", height: "25px",
                                                   backgroundColor: item.paid === "paid" ? "green" : "red",
                                               }}>{item.paid}  </div>
                                           </lable>
 
                                           <lable style={{ display: "block", fontSize: "12px" }}>Lco: {item.Lconame}
 
                                               <div style={{ fontSize: "12px", display: "inline-block", float: "right", color: "red" }}>({item.Lcocode})
 </div>
                                           </lable>
                                       </div>
                                   </li>
 
                               );
                           })}
                       </ul>
 
                       <Loader
                           visible={this.state.isShowStockLoader}
                           style={{ textAlign: "center", marginBottom: 10, }}
                           type="ThreeDots"
                           color="#00BFFF"
                           height="10"
                           width="20"
                       />
                   </div>
        </div>

        <RootDiv>
          <RootLeft>
            <RootTitle>Name :</RootTitle>

            <RootValue
              style={{ cursor: "pointer", marginRight: "150px " }}
              onClick={() => {
                this.props.onClickProfile();
              }}
            >
              {this.state.lcoName}{" "}
              <div className="lcocode">({this.state.lcocode})</div>
            </RootValue>
          </RootLeft>

          <RootRight>
            <RootTitle>Balance :</RootTitle>

            <RootValue>
              {"\u20B9"} {this.state.lcoBalance}
            </RootValue>
          </RootRight>
        </RootDiv>

        <Button
          variant="info"
          size="sm"
          className="ml-4 mt-1"
          onClick={e => {
            this.getUnMappedSTB(e);
          }}
        >
          Show Unmapped STB
        </Button>

        {this.renderUnmappedSTB()}

        {this.logOutFunc()}



        <Drawer

                    open={this.state.isShowMantainanceDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 ">


                            <label style={{ fontSize: 20, fontWeight: "bold", textAlign: "center", marginLeft: 20 }}>{this.state.maintText} </label>
                            <div style={{ width: 350, height: 250, marginBottom: 30 }}>
                                <img

                                    style={{ width: 350, height: 250 }}
                                    src={this.state.maintIMG} className="spinnerContent" color="green" />
                            </div>
                            <div style={{ display: "block", textAlign: "center", width: "100%" }}>
                                <Button
                                    style={{ backgroundColor: "#E64A19", borderColor: "#E64A19" }}
                                    // className="mt-3 pull-right"
                                    onClick={() => {
                                        this.getLCO_Details();


                                    }}
                                >Refresh</Button>

                            </div>

                        </Card.Body>
                    </Card>

                </Drawer>
      </Rootcontainer>
    );
  }
}
