import React from "react";
import {
    Button, ButtonGroup, Container, ButtonToolbar, Jumbotron, Card, Form, Row, Col,
    Dropdown, DropdownButton, Modal
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import {
    Rootcontainer,
    SearchDiv,
    MainContainer,
    RootDiv,
    RootTitle,
    RootBal,
    RootRight,
    Amt,
    MoreDetails,
    RootLeft,
    RootValue,
    CustomerStatus,
    BtnGroupRight,
    STBGroup,
    STBDetails,
    CustomerStatusDiv,
    ActivPackage,
    ChannelBox,
    ChannelName,
    ChannelPrice,
    AddPackageHeading
} from "./styled";
import "./index.css";
import { Redirect } from "react-router-dom";
import {Calendar} from 'react-calendar';
import GeneralValues from '../../GobelElements/Values';
import {
    MdPermIdentity,
    MdHome,
    MdFiberPin,
    MdCreditCard,
    MdRefresh,
    MdAddCircle,
    MdRemoveCircle,
    MdPowerSettingsNew,
    MdArrowBack
} from "react-icons/md";
import loadinggif from "../../assets/loding-gif.gif";
import API from "../../Api";
import logo from "../../assets/logo-3.png";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import Drawer from "react-drag-drawer";
import Undraw from "react-undraw";
import axios from 'axios';
import Date from './Date';
import Details from '../../GobelElements/Values';
import { FaPlus,FaCircle } from "react-icons/fa";
import Loader from 'react-loader-spinner';

 import "react-datepicker/dist/react-datepicker.css";
export default class Report extends React.Component {
    constructor(props) {
        super(props);


        this.state = {


            lcoID: localStorage.getItem("userID"),
            lcoPassword: localStorage.getItem("userPassword"),
            domainName: localStorage.getItem("domain"),
            isloggedOut: false,
            isShowLogOutDialog: false,
            lcoBalance: "",
            lcoName: "",
            lcocode: "",
            fromYear: 0,
            fromMon: 0,
            fromDay: 0,
            toYear: 0,
            toMon: 0,
            toDay: 0,
            whichDocument: "tinvoicelco",
            isSpinnerVisible: false,     
            isEmptyError:"",
            DocumentType: ["T invoicelco","Dump debitlog","Total customers","Expiry"],
            startDate:"",
            endDate:"",
            selectedMonth:2,
            selectedYear:2020 ,
            inStockListSearchText: "",
            inStockDataList: []
 

        };

        this.getLCO_Details();
        //this.getdata1()
        console.log("try", this.props.tableData);
    }
    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }
    componentWillMount() {
        window.removeEventListener("scroll", this.handleScroll);
    }
    calStartDate(value){
        console.log(value)
        // var d=new Date(value);
        console.log(value.getDate(),value.getMonth(),value.getFullYear())
        debugger;
        this.setState({fromDay:value.getDate(),fromMon:value.getMonth()+1,fromYear:value.getFullYear(),startDate:value})
        //console.log(d.getDate(),d.getMonth(),d.getFullYear())
        debugger;

    }
    calEndDate(value) {
        console.log(value)
        // var d=new Date(value);
        console.log(value.getDate(),value.getMonth()+1);
      
        this.setState({toDay:value.getDate(),toMon:value.getMonth()+1,toYear:value.getFullYear(),endDate:value})
        //console.log(d.getDate(),d.getMonth(),d.getFullYear())
        debugger;
    }

    getLCO_Details() {
        debugger;
        this.setState({ isSpinnerVisible: true });
        const { lcoID, lcoPassword, domainName } = this.state;
        API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
            .then(response => response.json())
            .then(responsejson => {
                this.setState({
                    lcoName: responsejson.lconame,
                    lcoBalance: responsejson.balance,
                    lcocode: responsejson.lcocode
                });

            });
    }

    setCasType(e) {
        console.log("selected value", e.target.value);
        this.setState({ whichDocument: e.target.value });
        if(e.target.value == "Total customers"){
             this.setState({isShowMonthlyBill:true});
        }else{
            this.setState({isShowMonthlyBill:false});
        }
    }
    getCSVFile() {
        let { fromDay, fromMon, fromYear, toDay, toMon, toYear, whichDocument, lcocode, lcoID, lcoPassword, domainName } = this.state;
        console.log(fromDay ,fromMon ,fromYear , toDay ,toMon , toYear , whichDocument)
        if(whichDocument == "Expiry"){
            whichDocument="expiry"               
         }else if(whichDocument == "Dump debitlog"){
            whichDocument="dumpdebitlog"
         }else if(whichDocument == "Total customers"){
            whichDocument="disconnected"
         }else{
            whichDocument="tinvoicelco"
         }
          if(whichDocument!="disconnected"){
        if (fromDay && fromMon && fromYear && toDay && toMon && toYear && whichDocument) {
              
             
            this.setState({isEmptyError:"",isSpinnerVisible:true});
            console.log("data in file",fromDay, fromMon, fromYear, toDay, toMon, toYear, whichDocument, lcocode,lcoID,lcoPassword);
            console.log("url",`${Details.url}/api/reportsdownload/?username=${lcoID}&password=${lcoPassword}&command=${whichDocument}&lcocode=${lcocode}&d1=${fromYear}-${fromMon}-${fromDay}&d2=${toYear}-${toMon}-${toDay}&json=yes`);
            // fetch(`${Details.url}/api/reportsdownload/?username=${lcoID}&password=${lcoPassword}&command=${whichDocument}&lcocode=${lcocode}&d1=${fromYear}-${fromMon}-${fromDay}&d2=${toYear}-${toMon}-${toDay}&json=yes`)
            // .then(res=>res.json())
            // .then(resJson=>{ 
            //     console.log("resjson",resJson);
            //     debugger;
            //     this.setState({ isSpinnerVisible: false, isEmptyError: "" });

            // }).catch(e=>{
            //     console.log("error",e);
            //     this.setState({ isSpinnerVisible: false, isEmptyError: "Error" });
            // })
            axios({ 
                
                url: `${Details.url}/api/reportsdownload/?username=${lcoID}&password=${lcoPassword}&command=${whichDocument}&lcocode=${lcocode}&d1=${fromYear}-${fromMon}-${fromDay}&d2=${toYear}-${toMon}-${toDay}`,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                console.log("data", response);
                debugger;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${whichDocument !="disconnected" ? whichDocument:"Total_customer"}.xlsx`);
                link.click();
                window.URL.revokeObjectURL(url);
                this.setState({ isSpinnerVisible: false });
            }).catch((error) => {
                console.log("error", error);
                this.setState({ isSpinnerVisible: false, isEmptyError: "Unable to process !" });

            });
        } else {
            this.setState({ isEmptyError: "Please fill all field !" })
        }
    }else{
        this.setState({ isSpinnerVisible: true});
        axios({
                
            url: `${Details.url}/api/reportsdownload/?command=${whichDocument}&username=${lcoID}&password=${lcoPassword}&asondate=${this.state.selectedYear}-${this.state.selectedMonth}&lcocode=${lcocode}&ssr=yes&csvdownload=yes`,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            console.log("data", response);
            debugger;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${whichDocument !="disconnected" ? whichDocument:"Total_customer"}.xlsx`);
            link.click();
            window.URL.revokeObjectURL(url);
            this.setState({ isSpinnerVisible: false,selectedMonth:1,selectedYear:2020 });
        }).catch((error) => {
            console.log("error", error);
            this.setState({ isSpinnerVisible: false, isEmptyError: "Unable to process !" });

        });
    }
    }

    logOutFunc() {
        if (this.state.isloggedOut) {
            return <Redirect to="/login" />;
        } else {
            return null;
        }
    }
    logOut() {
        localStorage.removeItem("userID");
        const username = localStorage.getItem("userID");
        if (!username) {
            this.setState({ isloggedOut: true });
        }
    }
    backTOTrandactions() {
        this.props.onMoveToTransactions();
    }
    stockListSearch(text) {
 
        var { lcoID, lcoPassword, domainName, inStockListSearchText } = this.state;
        if (text.length > 2) {
            this.setState({ inStockDataList: [], isShowInStockList: true, isShowStockLoader: true, noData: "" });
  
            API.stockListSearch(lcoID, lcoPassword, "liststock", text, 1, domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    if (responseJson.count) {
  
  
                        this.setState({ inStockDataList: responseJson.content, isShowInStockList: true, inStockCurrentPage: 1, totalCountInStock: responseJson.count, isShowStockLoader: false });
                        console.log("In stock list", this.state.inStockDataList);
                    } else {
                        this.setState({ inStockDataList: [], isShowStockLoader: false, noData: "No data found!" });
                    }
                });
        } else {
            this.setState({ isShowInStockList: false, isShowInStockList: false, isShowStockLoader: false, noData: "" });
        }
    }
    getSTBDetails(item) {
        console.log("item", item);
        if (item.Customer) {
          this.setState({ spinnerVisible: true });
          const { lcoID, lcoPassword, domainName, searchText } = this.state;
          API.STBSearch(lcoID, lcoPassword, "stbsearch", item.SNo, domainName)
            .then((response) => response.json())
            .then((responsejson) => {
              console.log("response", responsejson);
              if (responsejson.message == "no data") {
                this.setState({ spinnerVisible: false });
              } else {
                localStorage.setItem("currentlco", item.Lcocode);
                this.setState({ spinnerVisible: false });
                this.props.moveToCustomerDetails("customerdetails", responsejson, this.state.searchText);
              }
            });
        }
        else {
          this.setState({ isShowNodataDialog: true })
        }
   
      }
 
    render() {
        return (
            <Rootcontainer onScroll={e => this.handleScroll(e)}>
                <LoadingOverlay
                    className={this.state.isSpinnerVisible ? "displaySpinner" : ""}
                    overlay={true}
                    active={this.state.isSpinnerVisible}
                    spinner={
                        <img
                            src={loadinggif}
                            className="spinnerContent"
                            color="green"
                            style={{ marginLeft: 100 }}
                            alt="logo"
                        />
                    }
                ></LoadingOverlay>
                <Drawer
                    open={this.state.isShowLogOutDialog}
                    onRequestClose={this.toggle}
                    direction="left"
                >
                    <Card>
                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 15, fontWeight: "bold" }}>
                                Do you want{" "}
                                <span style={{ fontSize: "1.5em", color: "red" }}>Log out</span>{" "}
                                ?
              </div>
                            <Undraw className="undrawimg" name="monitor" />
                            <Button
                                style={{ marginLeft: 20 }}
                                className="success"
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.setState({ isShowLogOutDialog: false });
                                }}
                            >
                                Cancel
              </Button>

                            <Button
                                style={{ backgroundColor: "red" }}
                                className="danger"
                                className="mt-3 pull-right"
                                onClick={() => {
                                    // this.props.hindNodataDialog();
                                    this.logOut();
                                }}
                            >
                                OK
              </Button>
                        </Card.Body>
                    </Card>
                </Drawer>

                <div className="header-div">
                    <MdArrowBack
                        onClick={() => this.backTOTrandactions()}
                        className="back-button"
                    />
                    <h3 style={{ marginLeft: "70px",textTransform:"uppercase",marginRight:"-50px" }}>Report Download</h3>
                    <img
                    onClick={()=>{this.props.moveToDashBoard()}}
                        src={GeneralValues.circlelogo}
                        style={{ float: "left", marginLeft: " -168px" }}
                        className="logo-img"
                        alt="logo"
                    />

                    <MdPowerSettingsNew
                        onClick={() => {
                            this.setState({ isShowLogOutDialog: true });
                        }}
                        value="Logout"
                        className="fontSize login"
                    />
                    <input type="text" text="Search" placeholder="Global Search..."
                       onChange={(e) => {
                           this.setState({ inStockListSearchText: e.target.value })
                           this.stockListSearch(e.target.value);
                       }}
 
                       value={this.state.inStockListSearchText}
                       className="input-STB"
                       style={{ backgroundColor: "transparent", borderColor: "transparent", borderBottom: "2px solid white", color: "white", textAlign: "center", fontSize: 15, borderRight: "2px solid white", fontWeight: "bold",alignSelf:"end",marginLeft:"50%" }} />
                   <div style={{
                       position: "absolute",
                       display: this.state.isShowInStockList ? "block" : "none",
                       listStyle: "none",
                       backgroundColor: "white",
                       padding: 0,
                       boxShadow: "0px 1px 3px #e9b42a",
                       zIndex: 9,
                       width: "22vw",
                       top: 60,
                       overflow: "auto",
                       maxHeight: "30vh",
                       marginLeft:"65%"
 
                   }}
                       onScroll={(e) => this.handleScroll(e)}>
                       <label style={{ fontSize: 15, fontWeight: "bold", color: "rgb(26, 172, 195)", marginLeft: 10 }}>List:</label>
                       <div style={{ textAlign: "center", color: "gray" }}>{this.state.noData}</div>
                       <ul style={{ listStyle: "none", padding: 10 }}>
 
                           {this.state.inStockDataList.map((item, i) => {
 
                               return (
 
 
                                   <li className="search-list"
                                       onClick={() => { this.getSTBDetails(item) }}
                                       style={{ fontSize: 15, color: "black", lineHeight: 2, borderBottom: "1px solid rgba(26, 172, 195,0.5)", cursor: "pointer" }}>
                                       <div>
                                           <lable style={{ display: "block" }}>
                                               Name:
  {item.Customer ? item.Customer : <FaPlus style={{
                                                   background: " #0a3f65",
                                                   color: "white", width: "20px", height: "20px", padding: "2px", borderRadius: "10px", marginLeft: "8px"
                                               }} />}
 
                                               <FaCircle style={{
                                                   display: item.status ? "inline-block" : "none",
                                                   height: "12px", float: "right", marginTop: "8px",
                                                   color: item.status == "Live" ? "green" : "red",
                                               }} />
 
 
                                           </lable>
 
                                           <lable style={{ display: "block" }}>STB: {item.SNo}
 
                                               <div style={{
                                                   fontSize: "12px", display: item.paid ? "inline-block" : "none", float: "right", color: "white",
                                                   padding: "0px 7px", height: "25px",
                                                   backgroundColor: item.paid === "paid" ? "green" : "red",
                                               }}>{item.paid}  </div>
                                           </lable>
 
                                           <lable style={{ display: "block", fontSize: "12px" }}>Lco: {item.Lconame}
 
                                               <div style={{ fontSize: "12px", display: "inline-block", float: "right", color: "red" }}>({item.Lcocode})
 </div>
                                           </lable>
                                       </div>
                                   </li>
 
                               );
                           })}
                       </ul>
 
                       <Loader
                           visible={this.state.isShowStockLoader}
                           style={{ textAlign: "center", marginBottom: 10, }}
                           type="ThreeDots"
                           color="#00BFFF"
                           height="10"
                           width="20"
                       />
                   </div>
                </div>

                <RootDiv>
                    <RootLeft>
                        <RootTitle>Name :</RootTitle>

                        <RootValue
                            style={{ cursor: "pointer" }}
                        //   onClick={() => {
                        //     this.props.onClickProfile();
                        //   }}
                        >
                            {this.state.lcoName}
                        </RootValue>
                        <div className="lcocode">
                            (<span style={{ color: "black" }}>Lco code:</span>{" "}
                            {this.state.lcocode})
            </div>
                    </RootLeft>

                    <RootRight>
                        <RootTitle>Balance :</RootTitle>

                        <RootValue>
                            {"\u20B9"} {this.state.lcoBalance}
                        </RootValue>
                    </RootRight>
                </RootDiv>
                <MainContainer>

                    <Form className="form-div" style={{ marginTop: 20 }}>
                        <h4 style={{ fontSize: 15, fontWeight: "bold", alignSelf: "center", color: "red" }}>{this.state.isEmptyError}</h4>
                        <h3>Download form</h3>

                        <Row>
                            <Col xs={12} md={4} lg={3} sm={6} >
                                <Form.Group>
                                    <Form.Label>Type <span>*</span></Form.Label>
                                    <select title="DRM List" style={{ width: "80%", display: "block" }}
                                        onChange={(e) => { this.setCasType(e) }}

                                    >
                                        {this.state.DocumentType.map((item, i) => {
                                            return (
                                                <option value={item}>{item}</option>
                                            );
                                        })}
                                    </select>
                                </Form.Group>
                            </Col>



                          {!this.state.isShowMonthlyBill  ? <Col xs={12} md={4} lg={3} sm={6} >
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>From Date</Form.Label>
                                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                                    {/* <Calendar
                                            onChange={(e) => { this.calStartDate(e) }}
                                            formatMonth={Number}
                                            value={this.state.date2}


                                        /> */}
                                        <DatePicker
                                        onChange={(e) => { this.calStartDate(e) }}
                                        selected={this.state.startDate}
                                        
                                        />
                                    </div>

                                </Form.Group>

                            </Col>
:null}

{!this.state.isShowMonthlyBill  ?   <Col xs={12} md={4} lg={3} sm={6} >
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>To Date</Form.Label>
                                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                                    {/* <Calendar
                                            onChange={(e) => { this.calEndDate(e) }}
                                            formatMonth={Number}
                                            value={this.state.date}


                                        /> */}
                                        <DatePicker
                                        onChange={(e) => { this.calEndDate(e) }}
                                        selected={this.state.endDate}
                                        />
                                    </div>

                                </Form.Group>

                            </Col>
: null  }
{/* {this.state.isShowMonthlyBill  ?   <Col xs={12} md={4} lg={3} sm={6} >
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Month/Year</Form.Label>
                                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                                   
                                    <div style={{ float: "left" }}>
                                        <h4 style={{ fontSize: 12 }}>Month</h4>
                                        <select title="DRM List" style={{ width: "100%" }}
                                            onChange={(e) => { this.setState({ selectedMonth: e.target.value }) }}

                                        >
                                            {Date.month.map((item, i) => {
                                                return (
                                                    <option value={item}>{item}</option>
                                                );
                                            })}


                                        </select>
                                    </div>
                                    <div style={{ float: "left" }}>
                                        <h4 style={{ fontSize: 12, marginLeft: 5 }}>year</h4>
                                        <select title="DRM List" style={{ width: "100%" }}
                                            onChange={(e) => { this.setState({ selectedYear: e.target.value }) }}

                                        >
                                            {Date.year.map((item, i) => {
                                                return (
                                                    <option value={item}>{item}</option>
                                                );
                                            })}


                                        </select>
                                    </div>
                                </div>

                                </Form.Group>

                            </Col>
: null  } */}


                        </Row>
                        <div style={{ textAlign: "end", marginRight: 20 }}>
                            <Button
                                onClick={() => { this.getCSVFile() }}
                                style={{ textAlign: "flex-end" }}
                                variant="success"
                            >
                                Download
</Button>
                        </div>


                    </Form>

                </MainContainer>

                {this.logOutFunc()}
            </Rootcontainer>
        );
    }
}








