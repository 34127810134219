import React from 'react';
import Drawer from 'react-drag-drawer';
import '../../screens/CustomerDetails/index.css';
import {
    FaSearch
} from 'react-icons/fa';
import  './index.css';
import {
    MdClose,
    MdAddCircle,
    MdLiveTv
} from 'react-icons/md';
import Undraw from 'react-undraw';
import {
    Rootcontainer, SearchDiv, MainContainer, RootDiv, RootTitle, RootBal, RootRight, Amt, MoreDetails,
    RootLeft, RootValue, CustomerStatus, BtnGroupRight, STBGroup, STBDetails,
    CustomerStatusDiv, ActivPackage, ChannelBox, ChannelName, ChannelPrice, AddPackageHeading, SelectedPackage, SelectedDiv,
    BalanceDiv, CurrentBal, TotalBal, AvailableBal

} from './styled';
import { FormControl, FormGroup, FormLabel, Container, ControlLabel, HelpBlock, Checkbox, Radio, Button, InputGroup, Form, Card, Row, Col } from 'react-bootstrap';
export default class AddPack extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            isShowWarning: false,
            selectedPackageList:[],
            isShowChannelList:false
        }
    }
    toggle = () => {
        let { toggle } = this.state

        this.setState({ toggle: !toggle })
    }
    isShowDaily() {
        if (this.props.isDaily) {
            // debugger;
            return (
                <div style={{ marginLeft: 15, marginRight: 15 }}>

                    <label >Start Date : <span style={{ fontWeight: "bold", color: "red" }}>{this.props.startDate}</span></label>
                    <label style={{ marginLeft: "20px", }}>End Date : <span style={{ fontWeight: "bold", color: "red" }}>{this.props.endDate}</span></label>

                </div>
            );
        } else {
            // debugger;
            return null
        }
    }
    showBase(){
        if(this.props.basePackList.length){
            return(
                <div>
                     <h5 style={{ marginLeft: 20, marginTop: 5, marginBottom: 5 }}>Base Pack :</h5>
                            <div style={{ maxHeight: 100, overflow: "auto", borderTop: "1px solid #f1f1f1", padding: 21 }}>
                                <Row>
                                    {this.props.basePackList.map((data, i) => {
                                        return (
                                            <Col lg={4} md={6} sm={12} xs={12} className="mb-3 pr-0">
                                                <Card key={i} style={{ boxShadow: "1px 1px 3px 0px #fbfbfb", border: "1px solid rgba(134, 134, 134, 0.125)", maxHeight: 80 }}>

                                                    <Card.Body >
                                                        <label style={{ fontSize: 15, color: "#132275", fontWeight: 600, marginTop: -15 }}>{data.name}</label>
                                                        <label style={{ display: "inline", color: "#d61818", fontSize: 14 }}>  {'\u20B9'}{data.baseprice}</label>

                                                        <Form.Group controlId="formBasicChecbox" style={{ float: "left", marginBottom: 0 }}>
                                                            <Form.Check
                                                                onClick={() => this.props.toggleChangeInAddPackDialog(i, data.id, data.name, data.baseprice, data.toggleMode, data.base,data.channels)}
                                                                type="checkbox"
                                                                label={data.toggleMode}
                                                                checked={data.toggleMode} />
                                                        </Form.Group>
                                                       

                                                        {/* <div style={{ fontSize: 15, color: "#132275", fontWeight: 600,marginTop:-15}}>{data.name}</div>
                                                        <div style={{ display: "inline", color: "#d61818", float: "left",  fontSize: 14 }}>{'\u20B9'} {data.baseprice}   </div>
                                                        <Form.Group controlId="formBasicChecbox" style={{ float: "left", marginBottom: 0,}}>
                                                            <Form.Check
                                                                onClick={() => this.props.toggleChangeInAddPackDialog(i, data.id, data.name, data.baseprice, data.toggleMode, data.base)}
                                                                type="checkbox"
                                                                label={data.toggleMode}
                                                                checked={data.toggleMode} />
                                                        </Form.Group> */}



                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>
                </div>
            )
        }else{
            return null;
        }
    }
    render() {
        if (this.props.isShowAddPackageDialogBox) {
            return (
                <div>



                    <Drawer

                        open={this.props.isShowAddPackageDialogBox}
                        onRequestClose={this.toggle}
                        direction='left' className="popupwidth"
                    >
                        {/* <div>Hey Im inside a drawer!</div> */}


                        <div style={{ overflow: "auto", background: "#ffffff", display: "table", }}>
                            {/* <label style={{ fontSize: 15, fontWeight: "bold", color: "red" }}>{this.props.isEmptyErrorInAddPackDialog} asa</label> */}

                            <AddPackageHeading><MdAddCircle className="popup-icon" /> Add Packages</AddPackageHeading>
               {/* <center> <label style={{fontSize: 15, fontWeight: "bold", color: "red"}}>{this.props.isEmptyErrorInAddPackDialog}</label></center> */}
                            {this.isShowDaily()}
                            <BalanceDiv className="bal-div"><CurrentBal>Current Balance : <label>{'\u20B9'} {this.props.lcoBalance}</label></CurrentBal><TotalBal>Total Addon Package value : <label>{'\u20B9'} {this.props.selectedPackPrice}</label></TotalBal><AvailableBal>Remaining Balance : <label>{'\u20B9'} {(this.props.lcoBalance - this.props.selectedPackPrice).toFixed(2)}</label></AvailableBal></BalanceDiv>
                            <strong className="selected-nm">Selected Packages</strong>
                            <SelectedDiv className="selected-div">
                                {this.props.addPackageSelected.map((data, i) => {
                                    return (
                                        <SelectedPackage className="mr-3">{data.name} | {'\u20B9'} {data.price} <label>{data.id}</label> <MdClose onClick={() => { this.props.removeThisChannel(data.id) }} /></SelectedPackage>
                                    )
                                }
                                )}
                            </SelectedDiv>
                            <h5 style={{ marginLeft: 20, marginTop: 5, marginBottom: 5 }}>MSO Pack :</h5>
                            <div style={{ maxHeight: 100, overflow: "auto", borderTop: "1px solid #f1f1f1", padding: 21 }}>
                                <Row>
                                    {this.props.msoPackList.map((data, i) => {
                                        return (
                                            <Col lg={4} md={6} sm={12} xs={12} className="mb-3 pr-0">
                                                <Card key={i} style={{ boxShadow: "1px 1px 3px 0px #fbfbfb", border: "1px solid rgba(134, 134, 134, 0.125)", maxHeight: 80 }}>

                                                    <Card.Body >
                                                        <label style={{ fontSize: 15, color: "#132275", fontWeight: 600, marginTop: -15 }}>{data.name}</label>
                                                        <label style={{ display: "inline", color: "#d61818", fontSize: 14 }}>  {'\u20B9'}{data.baseprice}</label>

                                                        <Form.Group controlId="formBasicChecbox" style={{ float: "left", marginBottom: 0 }}>
                                                            <Form.Check
                                                                onClick={() => this.props.toggleChangeInAddPackDialog(i, data.id, data.name, data.baseprice, data.toggleMode, data.base,data.channels)}
                                                                type="checkbox"
                                                                label={data.toggleMode}
                                                                checked={data.toggleMode} />
                                                        </Form.Group>
                                                       

                                                        {/* <div style={{ fontSize: 15, color: "#132275", fontWeight: 600,marginTop:-15}}>{data.name}</div>
                                                        <div style={{ display: "inline", color: "#d61818", float: "left",  fontSize: 14 }}>{'\u20B9'} {data.baseprice}   </div>
                                                        <Form.Group controlId="formBasicChecbox" style={{ float: "left", marginBottom: 0,}}>
                                                            <Form.Check
                                                                onClick={() => this.props.toggleChangeInAddPackDialog(i, data.id, data.name, data.baseprice, data.toggleMode, data.base)}
                                                                type="checkbox"
                                                                label={data.toggleMode}
                                                                checked={data.toggleMode} />
                                                        </Form.Group> */}



                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>

                           {this.showBase()}

                            <SearchDiv style={{ float: "none", width: "97%", marginLeft: 11, marginRight: 11, background: "#f3f3f3" }} className="mt-2 mb-2">
                                <input value={this.state.searchText} type="text" className="textBox" placeholder="Search..." style={{ lineHeight: 2.3, background: "#f3f3f3", border: 0, width: "100%", borderRadius: 35 }}
                                    //    onPaste={this.renderTheSearchResult()}
                                    //    onKeyPress={this.renderTheSearchResult()}
                                    onChange={(e) => { this.setState({ searchText: e.target.value }) }}
                                    onKeyPress={(e) => {

                                        if (e.keyCode === 13 || e.which === 13) {
                                            this.props.onSearchPackage(this.state.searchText)
                                        }
                                    }}
                                />
                                <FaSearch className="search-icons"></FaSearch>
                            </SearchDiv>
                            <div style={{ float: "right", display: "block", width: "100%", paddingLeft: 20 }}>


                                <Button
                                    style={{ backgroundColor: "#00b33c" }}
                                    variant="info" size="sm" className="mr-1 ml-1 my-2 btn-size"
                                    onClick={() => { this.props.setCategory(this.props.packageAvailable) }}
                                >All</Button>


                                {/* <Button style={{
                                    backgroundColor: "#00b33c",
                                    borderColor: "#00b33c"
                                }}
                                    // style={{ backgroundColor: "#FFD200" }}
                                    onClick={() => { this.props.setCategory(this.props.basePackList) }}

                                    size="sm" className="mr-1 ml-1 my-2 btn-size">Base</Button> */}

                                <Button style={{
                                    backgroundColor: "#00b33c",
                                    borderColor: "#00b33c"
                                }}
                                    // style={{ backgroundColor: "#FFD200" }}
                                    onClick={() => { this.props.setCategory(this.props.baseTierPackList) }}

                                    size="sm" className="mr-1 ml-1 my-2 btn-size">Base Tier</Button>
                                <Button style={{
                                    backgroundColor: "#00b33c",
                                    borderColor: "#00b33c"
                                }}
                                    // style={{ backgroundColor: "#FFD200" }}
                                    onClick={() => { this.props.setCategory(this.props.broadcasterPackList) }}

                                    size="sm" className="mr-1 ml-1 my-2 btn-size">Broadcaster Bouquet</Button>
                                <Button style={{
                                    backgroundColor: "#00b33c",
                                    borderColor: "#00b33c"
                                }}
                                    // style={{ backgroundColor: "#FFD200" }}
                                    onClick={() => { this.props.setCategory(this.props.ALaCarte) }}

                                    size="sm" className="mr-1 ml-1 my-2 btn-size">A-La-Carte</Button>






                            </div>
                            <div style={{ height: 250, overflow: "auto", borderTop: "1px solid #f1f1f1", padding: 21, display: "block" }}>
                                <Row>
                                    {this.props.addPackageSearchResultList.map((data, i) => {
                                        return (
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-3 pr-0">
                                                <Card key={i} style={{ boxShadow: "1px 1px 3px 0px #fbfbfb", border: "1px solid rgba(134, 134, 134, 0.125)", maxHeight: 60 }}>

                                                    <Card.Body >
                                                        <label style={{ fontSize: 15, color: "#132275", fontWeight: 600, marginTop: -15 }}>{data.name}</label>
                                                        <label style={{ display: "inline", color: "#d61818", fontSize: 14 }}>  {'\u20B9'}{data.baseprice}</label>
                                                        <Form.Group controlId="formBasicChecbox" style={{ float: "left", marginBottom: 0 }}>
                                                            <Form.Check
                                                                onClick={() => this.props.toggleChangeInAddPackDialog(i, data.id, data.name, data.baseprice, data.toggleMode, data.base,data.channels)}
                                                                type="checkbox"
                                                                label={data.toggleMode}
                                                                checked={data.toggleMode} />
                                                        </Form.Group>
                                                        <div
                                                             onMouseOverCapture={() => { this.setState({ selectedPackageList: data.channels }) }}

                                                             className="above"
                                                             style={{float: "right",display: "inline"}}
                                                             >
                                                             <MdLiveTv color="gray" />
                                                            <div
                                                             style={{marginTop:-10,marginLeft:-150}}
                                                            >
                                                                <label style={{ fontSize: 15, fontWeight: "bold", color: "rgb(26, 172, 195)", marginLeft: 10 }}>Channels:</label>
                                                                <ul

                                                                    style={{ listStyle: "none", padding: 10 }}>
                                                                    {this.state.selectedPackageList.map((item, i) => {

                                                                        return (
                                                                            <li

                                                                                style={{ fontSize: 15, color: "black", lineHeight: 2, borderBottom: "1px solid rgba(26, 172, 195,0.5)", cursor: "pointer" }}>
                                                                                {item}</li>
                                                                        );
                                                                    })}
                                                                </ul>

                                                            </div>

                                                        </div>

                                                        {/* <div style={{ fontSize: 15, color: "#132275", fontWeight: 600,marginTop:-15 }}>{data.name}</div>
                                                        <div style={{ display: "inline", color: "#d61818", float: "left",  fontSize: 14 }}>{'\u20B9'} {data.baseprice}</div>
                                                        <Form.Group controlId="formBasicChecbox" style={{ float: "right", marginBottom: 0 }}>
                                                            <Form.Check
                                                                onClick={() => this.props.toggleChangeInAddPackDialog(i, data.id, data.name, data.baseprice, data.toggleMode, data.base)}
                                                                type="checkbox"
                                                                label={data.toggleMode}
                                                                checked={data.toggleMode} />
                                                        </Form.Group> */}



                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>
                            <div className="footer-div">
                                 <Button onClick={() => {
                                     if(parseInt(this.props.selectedPackPrice) < parseInt(this.props.lcoBalance)){
                                        this.setState({ isShowWarning: true })
                                     }else{
                                         this.props.showLowBalanceDialog();
                                     }
                                   
                                 }} variant="success" className="pull-right mt-1 mr-3 ml-3"> Confirm</Button >
                                  <Button onClick={() => {
                                     this.props.showAddPackageSchedulerDialog();
                                   
                                 }} variant="success" className="pull-right mt-1 mr-3 ml-3"> Schedule</Button >
                                
                                <Button variant="danger" className="pull-left mt-1 mr-3 ml-3" onClick={() => { this.props.inVisibleAddPackDialog() }}> Cancel</Button>
                            </div>
                        </div>

                    </Drawer>


                    <Drawer

                        open={this.state.isShowWarning}
                        onRequestClose={this.toggle}
                        direction='left'
                    >

                        <Card >


                            <Card.Body className="pl-3 pr-3">
                                <div style={{ fontSize: 18, fontWeight: "bold" }}>Add Packages</div>
                                <div style={{ fontSize: 18, }}>Do you want <span style={{ fontWeight: "bold", color: "red" }}>Add Packages </span>?</div>
                                <div style={{ textAlign: "end" }}>
                                    <Undraw
                                        style={{ marginTop: 10, display: 'block', width: 461, marginBottom: 10, height: 263 }}
                                        name="broadcast" />
                                    <Button
                                        style={{ marginRight: 15, backgroundColor: "red" }}
                                        className="danger" className="mt-3 pull-right"
                                        onClick={() => {
                                            this.setState({ isShowWarning: false });

                                        }}
                                    >Cancel</Button>
                                    <Button

                                        style={{ marginRight: 15, backgroundColor: "green" }}
                                        className="success" className="mt-3 pull-right"
                                        onClick={() => {
                                            this.setState({ isShowWarning: false });
                                            this.props.addPackage()

                                        }}
                                    >OK</Button>
                                </div>




                            </Card.Body>
                        </Card>

                    </Drawer>


                    <Drawer

                    open={this.props.isShowDuplicationChannelInAddPageDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >
                    {/* <div>Hey Im inside a drawer!</div> */}
                    <Card >

                        <Card.Body>
                            <div style={{ fontSize: 20, fontWeight: 'bold' }}>
                            <center> <label style={{fontSize: 15,}}>Selected package have <span style={{color:"red",fontWeight:"bold"}}>{this.props.isEmptyErrorInAddPackDialog}</span>  channel, but it already present in active package</label></center>
                                </div>
                                <Button variant="danger" className="pull-right mt-3"
                                onClick={() => { 
                                    this.props.removeThisChannel(this.props.selectedPackageID)
                                    this.props.hindDuplicationChannelInAddPageDialog() }}
                            >Remove</Button>
                            <Button variant="success" className="pull-right mt-3"
                            style={{marginRight:10}}
                                onClick={() => { this.props.hindDuplicationChannelInAddPageDialog() }}
                            >Ok</Button>



                        </Card.Body>
                    </Card>

                </Drawer>
                </div>

            );
        } else {
            return null;
        }
    }
}