import React, { Component } from 'react';
import logo from '../../assets/logo-2.png';
import Digital from 'react-activity/lib/Digital';
import Spinner from 'react-loading-overlay';
import 'react-activity/lib/Digital/Digital.css';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import LogIn from '../login';
import DashBoard from '../dashboard';
import BottomNav from '../BottomNavScreen';
import IdleTimer from 'react-idle-timer';


export default class index extends Component {
  constructor(props){
    super(props)

    this.state = {
        timeout:1000 ,
        showModal: false,
        userLoggedIn: false,
        isTimedOut: false,
        isloggedOut:false
    }
    const currentURL = window.location.pathname;
    console.log("cur",currentURL);
    debugger;
    this.idleTimer = null
    
  }

  
   
    
  doSomethingBeforeUnload = () => {

    localStorage.removeItem("userID");
   // debugger;
}
// resetTimer = () => {
//   // Do something
//   console.log("mani");
//   clearTimeout(this.idleTimer);
//   if(window.location.pathname !="/login"){
//   this.idleTimer = setTimeout(()=>{
//     console.log("mani");
//     localStorage.removeItem("userID");
//     window.location.href = "/login";
//     debugger;
   
// },10000); 
// }
  
 
// }

// Setup the `beforeunload` event listener
setupBeforeUnloadListener = () => {
 
    window.addEventListener("beforeunload", (ev) => {
        // ev.preventDefault();
        // console.log("eve",ev);
        return this.doSomethingBeforeUnload();
    });

};
  
componentDidMount() {
    // Activate the event listener
    this.setupBeforeUnloadListener();
}
  isLoggedInAlready() {
    const isUSer = localStorage.getItem("userID");
    console.log("userName", isUSer);
    debugger;
    if (isUSer) {
      return true;
    } else {
      return false;
    }
  }
  logOutFunc() {
    if (this.state.isloggedOut) {
      debugger;
      return  <Redirect to="/login" com />
    } else {
      return null;
    }
  }

  render() {
    const { match } = this.props
    console.log("rerun",this.state.isloggedOut);
    debugger;
    return (
      
      //  <div>

      //      <img src={logo} style={{position:"fixed",left:0,right:0,top:0,bottom:0,margin:"auto"}}/>
      //     <Digital
      //     animation={true}>

      //     </Digital>

      //  </div>
      // <div style={{display:"flex",position:"absolute",left:0,right:0,top:0,bottom:0}}>
      // <div style={{margin:"auto",display:"flex"}}>


      //     <img src={logo} style={{position:"fixed",left:0,right:0,top:0,bottom:0,margin:"auto"}}/>
      //     <Digital
      //     position="relative"
      //     top ={101}

      //     animation={true}
      //     >

      //     </Digital>
      //   </div>

      // </div>
      <div> 
         {/* <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout} /> */}
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={() => (
            
            this.isLoggedInAlready() ? (
             this.state.isloggedOut? <LogIn />:<Redirect to="/home" />
            ) : (
                <LogIn />
              )
          )} />
          <Route path="/home" component={BottomNav} />
          <Route path="/login" component={LogIn} />
          {/* {this.logOutFunc()} */}
        </Switch>
      </BrowserRouter>
      
      </div>
    );
  }
}




// import React, { Component } from 'react';
// import logo from '../../assets/logo-2.png';
// import Digital from 'react-activity/lib/Digital';
// import Spinner from 'react-loading-overlay';
// import 'react-activity/lib/Digital/Digital.css';
// import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
// import LogIn from '../login';
// import DashBoard from '../dashboard';
// import BottomNav from '../BottomNavScreen';
// import IdleTimer from 'react-idle-timer';


// export default class index extends Component {
//   constructor(props){
//     super(props)

//     this.state = {
//         timeout:1000 ,
//         showModal: false,
//         userLoggedIn: false,
//         isTimedOut: false,
//         isloggedOut:false
//     }
//     // const currentURL = window.location.pathname;
//     // console.log("cur",currentURL);
//     // debugger;
//     // this.idleTimer = null
    
//   }

  
   
    
// //   doSomethingBeforeUnload = () => {

// //     localStorage.removeItem("userID");
// //     debugger;
// // }
// // resetTimer = () => {
// //   // Do something
// //   console.log("mani");
// //   clearTimeout(this.idleTimer);
// //   if(window.location.pathname !="/login"){
// //   this.idleTimer = setTimeout(()=>{
// //     console.log("mani");
// //     localStorage.removeItem("userID");
// //     window.location.href = "/login";
// //     debugger;
   
// // },10000); 
// // }
  
 
// // }

// // Setup the `beforeunload` event listener
// // setupBeforeUnloadListener = () => {
 
// //     window.addEventListener("beforeunload", (ev) => {
// //         ev.preventDefault();
// //         console.log("eve",ev);
// //         return this.doSomethingBeforeUnload();
// //     });

// // };
  
// componentDidMount() {
//     // Activate the event listener
//     // this.setupBeforeUnloadListener();
// }
//   isLoggedInAlready() {
//     const isUSer = localStorage.getItem("userID");
//     console.log("userName", isUSer);
//     debugger;
//     if (isUSer) {
//       return true;
//     } else {
//       return false;
//     }
//   }
//   logOutFunc() {
//     if (this.state.isloggedOut) {
//       debugger;
//       return  <Redirect to="/login" com />
//     } else {
//       return null;
//     }
//   }

//   render() {
//     const { match } = this.props
//     console.log("rerun",this.state.isloggedOut);
//     debugger;
//     return (
      
//       //  <div>

//       //      <img src={logo} style={{position:"fixed",left:0,right:0,top:0,bottom:0,margin:"auto"}}/>
//       //     <Digital
//       //     animation={true}>

//       //     </Digital>

//       //  </div>
//       // <div style={{display:"flex",position:"absolute",left:0,right:0,top:0,bottom:0}}>
//       // <div style={{margin:"auto",display:"flex"}}>


//       //     <img src={logo} style={{position:"fixed",left:0,right:0,top:0,bottom:0,margin:"auto"}}/>
//       //     <Digital
//       //     position="relative"
//       //     top ={101}

//       //     animation={true}
//       //     >

//       //     </Digital>
//       //   </div>

//       // </div>
//       <div> 
//          {/* <IdleTimer
//             ref={ref => { this.idleTimer = ref }}
//             element={document}
//             onActive={this.onActive}
//             onIdle={this.onIdle}
//             onAction={this.onAction}
//             debounce={250}
//             timeout={this.state.timeout} /> */}
//       <BrowserRouter>
//         <Switch>
//           <Route exact path="/" render={() => (
            
//             this.isLoggedInAlready() ? (
//              this.state.isloggedOut? <LogIn />:<Redirect to="/home" />
//             ) : (
//                 <LogIn />
//               )
//           )} />
//           <Route path="/home" component={BottomNav} />
//           <Route path="/login" component={LogIn} />
//           {/* {this.logOutFunc()} */}
//         </Switch>
//       </BrowserRouter>
      
//       </div>
//     );
//   }
// }

