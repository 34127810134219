export const CAS = {
    ICAS_direct: [
        {
            sno: 1,
            model: "PRYSM SD",
            type: "SD",
            cas: "ICAS_direct"
        }
       
 
    ],
    ABV_direct: [
        {
            sno: 2,
            model: "WT-9999",
            type: "HD",
            cas: "ABV_direct"
        }
    ]
}