import React from "react";
import Drawer from "react-drag-drawer";
import "./index.css";
import {
  Button,
  ButtonGroup,
  Container,
  ButtonToolbar,
  Jumbotron,
  Card
} from "react-bootstrap";
import Undraw from "react-undraw";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import DateTimePicker from 'react-datetime-picker';
export default class DialogBoxs extends React.Component {
  constructor(props) {
    super(props);
    // previous push
    var newDate = new Date();
    var today = new Date();
    var remaining=0;
    if(newDate.getDate() >= 13){
        newDate.setMonth(newDate.getMonth()+1);
        newDate.setDate(13);
        remaining = Math.ceil((newDate-today)/(1000 * 60 * 60 * 24));

    }else{
      newDate.setDate(13);
      remaining = Math.ceil((newDate-today)/(1000 * 60 * 60 * 24));
    }


    this.state = {
      isShowTransferWarning: false,
      selectedLco: {},
      selectedSTB: "",
      extendValue: remaining,
      isShowWarningForExtend: false,
      addSchedulerDate: new Date(this.props.userMetaData.end),
      minDate:new Date(),
      removeSchedulerDate: new Date(this.props.userMetaData.end)
    }
  }

  isShowLcoSearchResult() {

    if (this.props.lcoSearchResult.length) {
      return (
        <div style={{ maxHeight: "40vh", overflow: "auto" }}>
          {this.props.lcoSearchResult.map((item, i) => {
            return (
              <div style={{ margin: 10, border: "1px solid gray", padding: 10, textAlign: "center" }}>
                <label style={{ fontSize: 18 }}>{item.name} <span style={{ color: "red", fontSize: 15 }}>({item.value})</span></label>
                <Button
                  onClick={() => { this.setState({ selectedLco: item, isShowTransferWarning: true }) }}
                  style={{ fontSize: 14, fontWeight: "bold", color: "white", backgroundColor: "green", borderColor: "green", marginLeft: 15 }}>Select</Button>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <Undraw
          style={{ marginTop: 20, display: 'block', width: 300, marginBottom: 10, height: 150, marginLeft: "-10px" }}
          name="cancel" />
      );
    }
  }

  isShowLowBalance(price) {
    var req = parseInt(price);
    var bal = parseInt(this.props.lcoBalance);
    if (req > bal) {
      return (
        <div>
          <label style={{ fontWeight: "bold", color: "red" }}>Low Balance !!</label>
        </div>
      );
    } else {
      return null;
    }
  }
  render() {
    var price = ((this.props.selectedAmount / 30) * this.state.extendValue).toFixed(2)
    var val = price * 0.18;
    price = parseInt(val) + parseInt(price);
    return (
      <div>
        <Drawer
          open={this.props.isShowActivateWarning}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 20, fontWeight: "bold" }}>Activate Customer</div>
              <Undraw
                primaryColor="green"
                style={{ width: 300, height: 200, display: 'block', marginTop: 15, marginBottom: 15, }}
                className="medicine" name="medicine" />

              <Button
                style={{ backgroundColor: "red", borderColor: "red" }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.props.hindActivateWarning();
                }}
              >
                cancel
              </Button>
              <Button
                style={{
                  backgroundColor: "green",
                  borderColor: "green",
                  marginRight: 15
                }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.props.hindActivateWarning();
                  this.props.activateCustomer();
                }}
              >
                OK
              </Button>


            </Card.Body>
          </Card>
        </Drawer>


        <Drawer
          open={this.props.isShowDeactivateWarning}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>Deactivate Customer</div>
              <Undraw
                primaryColor="red"
                style={{ width: 300, height: 200, display: 'block', marginTop: 15, marginBottom: 15, }}
                className="undrawimg" name="monitor" />

              <Button
                style={{ backgroundColor: "red", borderColor: "red" }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.props.hindDeactivateWarning();
                }}
              >
                cancel
              </Button>
              <Button
                style={{
                  backgroundColor: "green",
                  borderColor: "green",
                  marginRight: 15
                }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.props.hindDeactivateWarning();
                  this.props.deactivateCustomer();
                }}
              >
                OK
              </Button>


            </Card.Body>
          </Card>
        </Drawer>



        <Drawer

          open={this.props.isShowLcoSearchDialog}
          onRequestClose={this.toggle}
          direction='left'
        >

          <Card style={{ maxWidth: "80vh" }}>


            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 18, textAlign: "center" }}> LCO Search</div>
              <input
                style={{ marginLeft: "29%", marginTop: 10, marginBottom: 10, border: "2px solid black", padding: 5, alignItems: "center", justifyContent: "center" }}
                placeholder="Lco Search......"
                onChange={(e) => { this.props.lcoSearchFunc(e.target.value) }}
              />
              {this.isShowLcoSearchResult()}

              <Button
                style={{ backgroundColor: "red", borderColor: "red", marginRight: 15 }}
                className="mt-3 pull-right"
                onClick={() => {

                  this.props.hindLcoSearchDialog();

                }}
              >Cancel</Button>



            </Card.Body>
          </Card>

        </Drawer>


        <Drawer

          open={this.state.isShowTransferWarning}
          onRequestClose={this.toggle}
          direction='left'
        >

          <Card style={{ maxWidth: "80vh" }}>


            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 18 }}> Transfer STB</div>
              <div style={{ marginLeft: "30px", marginTop: 20 }}>
                <div>
                  <label style={{ fontSize: 15 }}>LCO name : <span style={{ fontWeight: "bold", color: "red" }}>{this.state.selectedLco.name}</span></label>

                </div>
                <div>
                  <label style={{ fontSize: 15 }}>LCO code : <span style={{ fontWeight: "bold", color: "red" }}>{this.state.selectedLco.value}</span></label>

                </div>

              </div>
              <Undraw
                style={{ marginTop: 20, display: 'block', width: 300, marginBottom: 10, height: 150, marginLeft: "-10px" }}
                name="connected" />



              <Button
                style={{ backgroundColor: "red", borderColor: "red" }}
                className="mt-3 pull-right"
                onClick={() => {

                  this.setState({ isShowTransferWarning: false })

                }}
              >cancel</Button>
              <Button
                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.props.hindLcoSearchDialog();
                  this.setState({ isShowTransferWarning: false });
                  this.props.transferFunc(this.state.selectedLco.value);

                }}
              >OK</Button>



            </Card.Body>
          </Card>

        </Drawer>

        <Drawer

          open={this.props.isEmptyList}
          onRequestClose={this.toggle}
          direction='left'
        >

          <Card >


            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 18, fontWeight: "bold", color: "red" }}>Please select STB </div>

              <Undraw
                primaryColor="red"
                style={{ marginTop: 10, display: 'block', width: 461, marginBottom: 10, height: 263 }}
                name="empty" />


              <Button
                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                className="mt-3 pull-right"
                onClick={() => {

                  this.props.hindEmptyList();

                }}
              >OK</Button>



            </Card.Body>
          </Card>

        </Drawer>

        <Drawer

          open={this.props.isShowExtendDialog}
          onRequestClose={this.toggle}
          direction='left'
        >

          <Card >


            <Card.Body className="pl-3 pr-3">
              {this.isShowLowBalance(price)}
              <div style={{ fontSize: 18, fontWeight: "bold" }}>  Extends</div>
              <div style={{ fontSize: 13, marginTop: 10 }}>How many day<span style={{ color: "red", fontWeight: "bold" }}> extends </span> ?</div>
              <div style={{ textAlign: "end", marginRight: 15 }}>Amount : <span style={{ fontWeight: "bold", color: "red" }}>{'\u20B9'} {price}</span></div>

              <input type="number" value={this.state.extendValue}
                onChange={(e) => { this.setState({ extendValue: e.target.value }) }}
                style={{ width: "40vh", marginTop: 10, border: "2px solid gray", padding: 5, }}
              />
              <div style={{ marginTop: 15, textAlign: "end" }}>
                <Button
                  style={{ backgroundColor: "red", borderColor: "red" }}
                  className="mt-3 pull-right"
                  onClick={() => {

                    this.props.hindExtendDialog();

                  }}
                >cancel</Button>
                { parseInt(price) < parseInt(this.props.lcoBalance)
                ?<Button
                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                className="mt-3 pull-right"
                onClick={() => {

                  this.setState({ isShowWarningForExtend: true });

                }}
              >OK</Button>
              :null
                }
  
                
              </div>



            </Card.Body>
          </Card>

        </Drawer>




        <Drawer

          open={this.state.isShowWarningForExtend}
          onRequestClose={this.toggle}
          direction='left'
        >

          <Card >


            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 18, fontWeight: "bold" }}>  Extends</div>
              <div style={{ fontSize: 13, marginLeft: 36, marginBottom: 20, marginTop: 5 }}>Do you want <span style={{ color: "red", fontWeight: "bold" }}>Extend</span> ?</div>
              <Undraw
                style={{ marginTop: 10, display: 'block', width: 300, marginBottom: 10, height: 150, marginLeft: 79 }}
                name="add-files" />

              <Button
                style={{ backgroundColor: "red", borderColor: "red" }}
                className="mt-3 pull-right"
                onClick={() => {

                  this.setState({ isShowWarningForExtend: false });

                }}
              >cancel</Button>
              <Button
                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                className="mt-3 pull-right"
                onClick={() => {

                  this.setState({ isShowWarningForExtend: false });
                  this.props.hindExtendDialog();
                  this.props.getExtendFunc(this.state.extendValue);

                }}
              >OK</Button>



            </Card.Body>
          </Card>

        </Drawer>

        <Drawer
          open={this.props.isShowSuspendWarning}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 20, fontWeight: "bold" }}>Suspend</div>
              <Undraw
                primaryColor="red"
                style={{ width: 300, height: 200, display: 'block', marginTop: 15, marginBottom: 15, }}
                className="medicine" name="medicine" />

              <Button
                style={{ backgroundColor: "red", borderColor: "red" }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.props.hindSuspendWarning();
                }}
              >
                cancel
              </Button>
              <Button
                style={{
                  backgroundColor: "green",
                  borderColor: "green",
                  marginRight: 15
                }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.props.hindSuspendWarning();
                  this.props.suspendCustomer();
                }}
              >
                OK
              </Button>


            </Card.Body>
          </Card>
        </Drawer>


        <Drawer

          open={this.props.isShowResumeWarning}
          onRequestClose={this.toggle}
          direction='left'
        >

          <Card style={{ maxWidth: "80vh" }}>


            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 18 }}> RESUME</div>
              <div style={{ marginLeft: "30px", marginTop: 10 }}> Do you want <span style={{ fontWeight: "bold", color: "red" }}> Resume</span> ?</div>

              <Undraw
                primaryColor="green"
                style={{ marginTop: 20, display: 'block', width: 300, marginBottom: 10, height: 150, marginLeft: "-10px" }}
                name="connected" />



              <Button
                style={{ backgroundColor: "red", borderColor: "red" }}
                className="mt-3 pull-right"
                onClick={() => {

                  this.props.hindResumeWarning();

                }}
              >cancel</Button>
              <Button
                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.props.hindResumeWarning();
                  this.props.resumeCustomer();



                }}
              >OK</Button>



            </Card.Body>
          </Card>

        </Drawer>


        <Drawer

          open={this.props.isShowMantainanceDialog}
          onRequestClose={this.toggle}
          direction='left'
        >

          <Card >


            <Card.Body className="pl-3 ">


              <label style={{ fontSize: 20, fontWeight: "bold", textAlign: "center", marginLeft: 20 }}>{this.props.maintText} </label>
              <div style={{ width: 350, height: 250, marginBottom: 30 }}>
                <img

                  style={{ width: 350, height: 250 }}
                  src={this.props.maintIMG} className="spinnerContent" color="green" />
              </div>
              <div style={{ display: "block", textAlign: "center", width: "100%" }}>
                <Button
                  style={{ backgroundColor: "#E64A19", borderColor: "#E64A19" }}
                  // className="mt-3 pull-right"
                  onClick={() => {
                    this.props.getLOC_Details();


                  }}
                >Refresh</Button>

              </div>

            </Card.Body>
          </Card>

        </Drawer>


        <Drawer
          open={this.props.isShowLowBalanceDialog}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 20, fontWeight: "bold" }}>Low Balance</div>
              <Undraw
                primaryColor="red"
                style={{ width: 300, height: 200, display: 'block', marginTop: 15, marginBottom: 15, }}
                className="wallet" name="wallet" />

              
              <Button
                style={{
                  backgroundColor: "green",
                  borderColor: "green",
                  marginRight: 15
                }}
                className="mt-3 pull-right"
                onClick={() => {
                 this.props.hindLowBalanceDialog()
                }}
              >
                OK
              </Button>


            </Card.Body>
          </Card>
        </Drawer>


        <Drawer
          open={this.props.isShowAddPackageSchedulerDialog}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 20, fontWeight: "bold",marginBottom:15 }}>Select the date </div>
            
              <DatePicker
                                onChange={(e) => { this.setState({addSchedulerDate:e}) }}
                                selected={this.state.addSchedulerDate}
                                minDate={this.state.minDate}
                              
                            />
              <div>
              <Button
                style={{
                  backgroundColor: "green",
                  borderColor: "green",
                  marginRight: 15,marginTop:30,display:"block",alignSelf:"flex-end"
                }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.props.hindAddPackageSchedulerDialog();
                }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  backgroundColor: "green",
                  borderColor: "green",
                  marginRight: 15,marginTop:30,display:"block",alignSelf:"flex-end"
                }}
                className="mt-3 pull-right"
                onClick={() => {
                 this.props.hindAddPackageSchedulerDialog();
                 this.props.addPackageSchedule(this.state.addSchedulerDate);
                }}
              >
                Ok
              </Button>
              </div>
              


            </Card.Body>
          </Card>
        </Drawer>


        <Drawer
          open={this.props.isShowRemovePackageSchedulerDialog}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 20, fontWeight: "bold",marginBottom:15 }}>Select the date </div>
            
              <DatePicker
                                onChange={(e) => { this.setState({removeSchedulerDate:e}) }}
                                selected={this.state.removeSchedulerDate}
                                minDate={this.state.minDate}
                              
                            />
              <div>
              <Button
                style={{
                  backgroundColor: "green",
                  borderColor: "green",
                  marginRight: 15,marginTop:30,display:"block",alignSelf:"flex-end"
                }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.props.hindRemovePackageSchedulerDialog();
                }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  backgroundColor: "green",  
                  borderColor: "green",
                  marginRight: 15,marginTop:30,display:"block",alignSelf:"flex-end"
                }}
                className="mt-3 pull-right"
                onClick={() => {
                 this.props.hindRemovePackageSchedulerDialog();
                 this.props.removePackageSchedule(this.state.removeSchedulerDate);
                }}
              >
                Ok
              </Button>
              </div>
              


            </Card.Body>
          </Card>
        </Drawer>

       




      </div>
    );
  }
}
